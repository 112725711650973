import { ComponentPropsWithRef, useRef, useState } from "react";

import { useMutableRefObject, useOnClickOutside } from "./utils";

export type Option = {
  label: string;
  id: string;
  value?: string;
};

export type UseAutocompleteOptions<T> = {
  autoHighlight?: boolean;
  defaultInputValue?: string;
  filterOption?: (inputValue: string | undefined, option: T) => boolean;
  id: string;
  listboxLabel?: string;
  onInputChange?: (value: string) => void;
  onSelect?: (option: T | undefined) => void;
  options: T[];
  selectedOption?: T;
  ["data-testid"]?: string;
};

export const useAutocomplete = <T extends Option>({
  autoHighlight,
  defaultInputValue,
  filterOption,
  id,
  listboxLabel,
  onInputChange,
  onSelect,
  options,
  selectedOption,
  ["data-testid"]: dataTestid,
}: UseAutocompleteOptions<T>) => {
  const [inputValue, setInputValue] = useState(defaultInputValue || selectedOption?.label);
  const [highlightedOption, setHighlightedOption] = useState<T | undefined>(
    autoHighlight ? options[0] : undefined
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const internalRef = useMutableRefObject<HTMLDivElement | null>(null);
  const listboxRef = useRef<HTMLUListElement>(null);
  const optionRefs = useRef<Record<string, HTMLLIElement>>({});
  const [showListbox, setShowListbox] = useState(false);

  const closeListbox = () => {
    setShowListbox(false);
    setHighlightedOption(undefined);
  };

  useOnClickOutside(internalRef.current, closeListbox);

  const openListbox = () => {
    setShowListbox(true);
    if (selectedOption) {
      requestAnimationFrame(() => {
        setHighlightedOption(selectedOption);
        scrollOptionIntoView(optionRefs.current[selectedOption.id]);
      });
    }
  };

  const filterOptions = (searchTerm: string | undefined) => {
    if (filterOption) {
      return options.filter((option) => filterOption(searchTerm, option));
    }

    if (!searchTerm) {
      return options;
    }

    const searchTermChunks = searchTerm.toUpperCase().split(" ") || [];
    return options.filter((option) =>
      searchTermChunks.every((chunk) =>
        (option.value || option.label).toUpperCase().includes(chunk)
      )
    );
  };

  const getOptionsToShow = (searchTerm: string | undefined): (T | undefined)[] => {
    return selectedOption?.label === searchTerm ? options : filterOptions(searchTerm);
  };
  const optionsToShow = getOptionsToShow(inputValue);

  const populateInputField = (value: string) => {
    onInputChange?.(value);
    setInputValue(value);
  };

  const scrollOptionIntoView = (option: HTMLLIElement | undefined) => {
    if (option) {
      const optionRect = option.getBoundingClientRect();
      const listboxRect = listboxRef.current?.getBoundingClientRect() || { top: 0, bottom: 0 };
      const isOptionOutsideView =
        optionRect.top < listboxRect.top || optionRect.bottom > listboxRect.bottom;

      if (isOptionOutsideView) {
        option.scrollIntoView({ block: "nearest" });
      }
    }
  };

  const selectOption = (option: T | undefined) => {
    inputRef.current?.focus();
    populateInputField(option?.label || "");
    onSelect?.(option);
    closeListbox();
  };

  const findNextOption = (key: "ArrowUp" | "ArrowDown"): T | undefined => {
    const index =
      autoHighlight && !highlightedOption
        ? 0
        : optionsToShow.findIndex((option) => option && highlightedOption?.id === option.id);

    const optionsLength = optionsToShow.length;

    return key === "ArrowDown"
      ? optionsToShow[(index ?? -1) + 1] || optionsToShow[0]
      : optionsToShow[(index ?? optionsLength) - 1] || optionsToShow[optionsLength - 1];
  };

  const highlightNextOption = (key: "ArrowUp" | "ArrowDown") => {
    !showListbox && openListbox();
    const nextOption = findNextOption(key);

    setHighlightedOption(nextOption);
    nextOption && scrollOptionIntoView(optionRefs.current[nextOption.id]);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowUp":
      case "ArrowDown":
        e.preventDefault(); // Preventing default to not move cursor in input
        highlightNextOption(e.key);
        break;
      case "Enter": {
        const optionToSelect = highlightedOption || (autoHighlight ? options[0] : undefined);
        showListbox && selectOption(optionToSelect);
        !showListbox && openListbox();
        break;
      }
      case "Escape":
      case "Tab":
        closeListbox();
        break;
    }
  };

  const listBoxProps: ComponentPropsWithRef<"ul"> & { "data-testid"?: string } = {
    "aria-label": listboxLabel,
    "aria-expanded": showListbox,
    "data-testid": `${dataTestid || id}-listbox`,
    id: `${id}-listbox`,
    ref: listboxRef,
    role: "listbox",
  };

  const inputProps: ComponentPropsWithRef<"input"> & { "data-testid"?: string } = {
    "aria-activedescendant": highlightedOption
      ? `${id}-listbox-item-${highlightedOption.id}`
      : undefined,
    "aria-autocomplete": "list",
    "aria-controls": listBoxProps.id,
    "aria-expanded": showListbox,
    "data-testid": `${dataTestid || id}-input`,
    autoComplete: "off",
    id: `${id}-input`,
    onChange: (e) => {
      const nextOptionsToShow = getOptionsToShow(e.target.value);
      populateInputField(e.target.value);
      setHighlightedOption(undefined);
      !showListbox && openListbox();
      autoHighlight &&
        nextOptionsToShow[0] &&
        scrollOptionIntoView(optionRefs.current[nextOptionsToShow[0].id]);
    },
    onClick: () => openListbox(),
    onFocus: () => {
      openListbox();
      inputRef.current?.select();
    },
    onKeyDown,
    ref: inputRef,
    role: "combobox",
    value: inputValue,
  };

  const getListBoxItemProps = (
    option: T,
    index: number
  ): ComponentPropsWithRef<"li"> & {
    "data-testid"?: string;
    highlighted: boolean;
    selected: boolean;
  } => {
    return {
      "aria-disabled": "false",
      "aria-selected": option.id === selectedOption?.id,
      "data-testid": `${dataTestid || id}-listbox-item-${option.id}`,
      id: `${id}-listbox-item-${option.id}`,
      key: option.id,
      onMouseMove: () => option.id !== highlightedOption?.id && setHighlightedOption(option),
      onMouseUp: () => selectOption(option),
      ref: (el) => el && (optionRefs.current[option.id] = el),
      role: "option",
      tabIndex: -1,
      selected: option.id === selectedOption?.id,
      highlighted:
        option.id === highlightedOption?.id ||
        (!!autoHighlight && !highlightedOption && index === 0),
    };
  };

  return {
    closeListbox,
    findNextOption,
    highlightedOption,
    highlightNextOption,
    id,
    inputProps,
    inputValue,
    internalRef,
    selectedOption,
    listboxRef,
    onKeyDown,
    openListbox,
    optionRefs,
    options,
    optionsToShow,
    populateInputField,
    scrollOptionIntoView,
    selectOption,
    setHighlightedOption,
    setInternalInputValue: setInputValue,
    showListbox,
    listBoxProps,
    getListBoxItemProps,
  };
};
