import React, { ComponentPropsWithRef, ForwardedRef, forwardRef, ReactNode } from "react";

import styles from "./b2x-autocomplete.module.scss";
import { Option, useAutocomplete, UseAutocompleteOptions } from "./useAutocomplete";

type Props<T> = UseAutocompleteOptions<T> & {
  renderInput: (props: ComponentPropsWithRef<"input">) => ReactNode;
};

const rootClassName = "b2x-autocomplete";
export const B2xAutocompleteComponent = <T extends Option>(
  { renderInput, ...useAutocompleteProps }: Props<T>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    id,
    inputProps,
    internalRef,
    optionsToShow,
    showListbox,
    getListBoxItemProps,
    listBoxProps,
  } = useAutocomplete(useAutocompleteProps);

  const renderListboxItem = (option: T, index: number) => {
    const { selected, highlighted, ...props } = getListBoxItemProps(option, index);
    const listBoxItemRootClassName = `${rootClassName}__listbox-item`;
    const highlightedClass = highlighted && styles[listBoxItemRootClassName + "--highlighted"];
    const selectedClass = selected && styles[listBoxItemRootClassName + "--selected"];

    const className = [styles[listBoxItemRootClassName], highlightedClass, selectedClass]
      .filter((name): name is string => !!name)
      .join(" ");

    return (
      <li {...props} className={className}>
        {option.label}
      </li>
    );
  };

  const setRootRef = (node: HTMLDivElement | null) => {
    internalRef.current = node;
    if (typeof ref === "function") {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }
  };

  return (
    <div id={id} ref={setRootRef} className={styles[rootClassName]}>
      {renderInput(inputProps)}
      {showListbox && !!optionsToShow.length && (
        <div>
          <ul {...listBoxProps} className={styles[rootClassName + "__listbox"]}>
            {optionsToShow.filter((option): option is T => !!option).map(renderListboxItem)}
          </ul>
        </div>
      )}
    </div>
  );
};

export const Autocomplete = forwardRef(B2xAutocompleteComponent);
Autocomplete.displayName = "Autocomplete";
