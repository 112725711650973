import { MutableRefObject, useCallback, useEffect, useRef } from "react";

export const useMutableRefObject = <T>(value: T): MutableRefObject<T> => {
  return useRef<T>(value) as MutableRefObject<T>;
};

export const useOnClickOutside = (element: HTMLElement | null, callback: () => void) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (element && !element.contains(event.target as Node)) {
        callback();
      }
    },
    [callback, element]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
};
